:root,
[data-mui-color-scheme="light"] {
  --background: 0 0% 100%; /* white */
  --foreground: 0 0% 3.9%; /* neutral-950 */

  --card: 0 0% 100%; /* white */
  --card-foreground: 0 0% 3.9%; /* neutral-950 */

  --popover: 0 0% 100%; /* white */
  --popover-foreground: 0 0% 3.9%; /* neutral-950 */

  --tooltip: 0 0% 3.9%; /* neutral-950 */
  --tooltip-foreground: 0 0% 98%; /* neutral-50 */

  --primary: 18.9deg 100% 47.3%; /* orange-600 (rimo-orange: #f14c00) */
  --primary-foreground: 0 0% 98%; /* neutral-50 */
  --primary-hover: 16deg 98% 40%; /* orange-700 */

  --secondary: 0 0% 3.9%; /* neutral-950 */
  --secondary-foreground: 0 0% 98%; /* neutral-50 */
  --secondary-hover: 0, 0%, 25%; /* neutral-700 */

  --muted: 0 0% 96.1%; /* neutral-100 */
  --muted-foreground: 0 0% 45.1%; /* neutral-500 */

  --disabled: 0 0% 64.1%; /* neutral-400 */
  --disabled-foreground: 0 0% 83.1%; /* neutral-300 */

  --accent: 0 0% 96.1%; /* neutral-100 */
  --accent-foreground: 0 0% 9%; /* neutral-900 */

  --destructive: 0 72.2% 50.5%; /* red-600 */
  --destructive-foreground: 0 0% 98%; /* neutral-50 */
  --destructive-background: 0 85.7% 97.2%; /* red-50 */
  --destructive-hover: 0 73.7% 41.7%; /* red-700 */
  --destructive-background-hover: 0 93.3% 94.1%; /* red-100 */

  --border: 0 0% 89.8%; /* neutral-200 */
  --input: 0 0% 89.8%; /* neutral-200 */
  --selected: 32, 100%, 96%; /* orange-50 */
  --ring: 221 83% 53%; /* blue-600 */

  --link: 221 83% 53%; /* blue-600 */

  --chart-orange-1: 11, 89%, 15%; /* orange-950 */
  --chart-orange-2: 14, 83%, 28%; /* orange-900 */
  --chart-orange-3: 13, 87%, 34%; /* orange-800 */
  --chart-orange-4: 16, 98%, 40%; /* orange-700 */
  --chart-orange: 18.9deg 100% 47.3%; /* orange-600 (rimo-orange: #f14c00) */
  --chart-orange-6: 24, 100%, 52%; /* orange-500 */
  --chart-orange-7: 26, 100%, 60%; /* orange-400 */
  --chart-orange-8: 30, 100%, 71%; /* orange-300 */
  --chart-orange-9: 32, 100%, 82%; /* orange-200 */
  --chart-orange-10: 34, 100%, 91%; /* orange-100 */

  --chart-red: 0, 72%, 51%; /* red-600 */
  --chart-yellow: 41, 96%, 40%; /* yellow-600 */
  --chart-lime: 85, 85%, 35%; /* lime-600 */
  --chart-green: 142, 76%, 36%; /* green-600 */
  --chart-emerald: 161, 94%, 30%; /* emerald-600 */
  --chart-teal: 175, 84%, 32%; /* teal-600 */
  --chart-cyan: 192, 91%, 36%; /* cyan-600 */
  --chart-sky: 200, 98%, 39%; /* sky-600 */
  --chart-blue: 221, 83%, 53%; /* blue-600 */
  --chart-indigo: 243, 75%, 59%; /* indigo-600 */
  --chart-violet: 262, 83%, 58%; /* violet-600 */
  --chart-purple: 271, 81%, 56%; /* purple-600 */
  --chart-fuchsia: 293, 69%, 49%; /* fuchsia-600 */
  --chart-pink: 333, 71%, 51%; /* pink-600 */
  --chart-rose: 347, 77%, 50%; /* rose-600 */

  --sidebar-background: 0 0% 98%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: 240 4.8% 95.9%;
  --sidebar-accent-foreground: 240 5.9% 10%;
  --sidebar-border: 220 13% 91%;
  --sidebar-ring: 217.2 91.2% 59.8%;
}

[data-mui-color-scheme="dark"] {
  --background: 0 0% 3.9%; /* neutral-950 */
  --foreground: 0 0% 98%; /* neutral-50 */

  --card: 0 0% 3.9%; /* neutral-950 */
  --card-foreground: 0 0% 98%; /* neutral-50 */

  --popover: 0 0% 3.9%; /* neutral-950 */
  --popover-foreground: 0 0% 98%; /* neutral-50 */

  --tooltip: 0 0% 89%; /* neutral-200 */
  --tooltip-foreground: 0 0% 3.9%; /* neutral-950 */

  --primary: 18.9deg 100% 47.3%; /* orange-600 (rimo-orange: #f14c00) */
  --primary-foreground: 0 0% 98%; /* neutral-50 */
  --primary-hover: 16deg 98% 40%; /* orange-700 */

  --secondary: 0 0% 25%; /* neutral-700 */
  --secondary-foreground: 0 0% 98%; /* neutral-50 */
  --secondary-hover: 0 0% 32.1; /* neutral-600 */

  --muted: 0 0% 14.9%; /* neutral-800 */
  --muted-foreground: 0 0% 83.1%; /* neutral-300 */

  --disabled: 0 0% 64.1%; /* neutral-400 */
  --disabled-foreground: 0 0% 45.1%; /* neutral-500 */

  --accent: 0 0% 14.9%; /* neutral-800 */
  --accent-foreground: 0 0% 98%; /* neutral-50 */

  --destructive: 0 72.2% 50.5%; /* red-600 */
  --destructive-foreground: 0 0% 98%; /* neutral-50 */
  --destructive-background: 0 85.7% 97.2%; /* red-50 */
  --destructive-hover: 0 73.7% 41.7%; /* red-700 */
  --destructive-background-hover: 0 93.3% 94.1%; /* red-100 */

  --border: 0, 0%, 25%; /* neutral-700 */
  --input: 0, 0%, 25%; /* neutral-700 */
  --selected: 0 0% 14.9%; /* neutral-800 */
  --ring: 217.2 91.2% 59.8%; /* blue-500 */

  --link: 217.2 91.2% 59.8%; /* blue-500 */

  --chart-orange-1: 11, 89%, 15%; /* orange-950 */
  --chart-orange-2: 14, 83%, 28%; /* orange-900 */
  --chart-orange-3: 13, 87%, 34%; /* orange-800 */
  --chart-orange-4: 16, 98%, 40%; /* orange-700 */
  --chart-orange-primary: 18.9deg 100% 47.3%; /* orange-600 (rimo-orange: #f14c00) */
  --chart-orange-6: 24, 100%, 52%; /* orange-500 */
  --chart-orange-7: 26, 100%, 60%; /* orange-400 */
  --chart-orange-8: 30, 100%, 71%; /* orange-300 */
  --chart-orange-9: 32, 100%, 82%; /* orange-200 */
  --chart-orange-10: 34, 100%, 91%; /* orange-100 */

  --chart-red: 0, 72%, 51%; /* red-600 */
  --chart-yellow: 41, 96%, 40%; /* yellow-600 */
  --chart-lime: 85, 85%, 35%; /* lime-600 */
  --chart-green: 142, 76%, 36%; /* green-600 */
  --chart-emerald: 161, 94%, 30%; /* emerald-600 */
  --chart-teal: 175, 84%, 32%; /* teal-600 */
  --chart-cyan: 192, 91%, 36%; /* cyan-600 */
  --chart-sky: 200, 98%, 39%; /* sky-600 */
  --chart-blue: 221, 83%, 53%; /* blue-600 */
  --chart-indigo: 243, 75%, 59%; /* indigo-600 */
  --chart-violet: 262, 83%, 58%; /* violet-600 */
  --chart-purple: 271, 81%, 56%; /* purple-600 */
  --chart-fuchsia: 293, 69%, 49%; /* fuchsia-600 */
  --chart-pink: 333, 71%, 51%; /* pink-600 */
  --chart-rose: 347, 77%, 50%; /* rose-600 */

  --sidebar-background: 240 5.9% 10%;
  --sidebar-foreground: 240 4.8% 95.9%;
  --sidebar-primary: 224.3 76.3% 48%;
  --sidebar-primary-foreground: 0 0% 100%;
  --sidebar-accent: 240 3.7% 15.9%;
  --sidebar-accent-foreground: 240 4.8% 95.9%;
  --sidebar-border: 240 3.7% 15.9%;
  --sidebar-ring: 217.2 91.2% 59.8%;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  img,
  video {
    max-width: none;
    height: auto;
  }
}
