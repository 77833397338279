@import "../../../libs/frontend/src/styles/reset.css";

@import "tailwindcss/base";
@import "../../../libs/ui/tailwind.base.css";

@import "tailwindcss/components";
/* Load components styles */
@import "../../../libs/frontend/src/features/note/transcriptEditor.css";

@import "tailwindcss/utilities";
