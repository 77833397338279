
.transcript-editor {
  p {
    @apply text-sm my-4 leading-6;
  }
  .transcript-portal {
    @apply text-neutral-400 text-xs data-[playing=true]:text-primary;
  }

  &.empty-content {
    @apply hidden;
  }

  &[data-has-audio=true] {
    .transcript-portal {
      @apply hover:cursor-pointer;
    }
  }

  &.selection {
    @apply bg-orange-300;
  }
}
